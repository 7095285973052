import React, { useState, useEffect } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react";

const Page = ({ blok }) => {
  const [isWhiteBackground, setIsWhiteBackground] = useState();
  useEffect(() => {
    // Check if the specified value is present in the array
    //71bc1b70-46bb-425b-88f3-b4fca5ea9882 - this value for white color navbar
    const hasWhiteBackground = blok?.globalHeader?.some(
      (item) => item === "71bc1b70-46bb-425b-88f3-b4fca5ea9882"
    );
    // Update the state based on the result
    setIsWhiteBackground(hasWhiteBackground);
  }, [blok?.globalHeader]);
  return (
    <main
      className={`flex flex-col md:gap-[200px] gap-[120px] ${
        isWhiteBackground ? "bg-white" : "" // Conditionally add background color
      }`}
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      {blok?.body
        ? blok.body.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))
        : <StoryblokComponent blok={blok} key={blok._uid} />}
    </main>
  );
};

export default Page;
