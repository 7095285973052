import React from "react";
import { renderRichText, storyblokEditable } from "@storyblok/react";
 
const globalMapCards = ({ blok }) => {
  const renderedRichText = renderRichText(blok?.description);
  return (
    <div
      className="lg:px-24 md:px-24 mb:px-8 container mx-auto font-aileron flex flex-col  md:mb-[100px] mb-[160px] relative px-4 h-[100%]"
      {...storyblokEditable(blok)}
      key={blok?._uid}
    >
      <img
        src="./images/Blurred_shapes3.png"
        alt="Events"
        className="absolute md:left-[-10%] left-[-16%] top-[-120%] z-[1] hidden md:block"
      />
      <img
        src={blok?.background.filename}
        alt="Events"
        className="md:hidden block z-[1] mb-10"
      />
      <img
        src="./images/globalmap_Mobile_Blurred_shapes2.png"
        alt="Events"
        className="absolute left-[-159vw] top-[5%] z-[1]  w-[1379px] max-w-[1369px] md:hidden opacity-60"
      />
      <img
        src="./images/globalmap_desktop_Blurred_shapes2.png"
        alt="Events"
        className="absolute left-[-53%] top-[-36%] z-[1]  w-[100vw] max-w-[100vw] md:block hidden opacity-40"
      />
      <div className="z-[12] text-4XL font-heavy leading-10 text-white max-md:max-w-full">
        {blok?.Title}
      </div>
      {blok?.Title && <div className="bg-light-green h-[2px] w-[12rem] md:w-[9rem] bottom-5 mt-4 z-[12] " />}
      <div className="font-lora text-large-regular mt-11 leading-7 text-white max-md:mt-10 max-md:max-w-full z-[12]">
        {/* {blok?.description} */}
        <div className='richtext  text-white' dangerouslySetInnerHTML={{ __html: renderedRichText }} />
      </div>
      <div className="self-stretch z-[12] mt-20 md:w-[90%] w-[100%] max-md:px-5 max-md:max-w-full mx-auto">
        <div className="flex  md:gap-7 gap-48 justify-center mx-auto items-center md:flex-nowrap flex-wrap">
          {blok?.overlayCards.map((blok) => {
            return (
              <a href={blok?.link?.cached_url}>
                <div
                  key={blok?._uid}
                  className="h-[295px] w-[290px] cursor-pointer relative image-container rounded overflow-visible bg-cover bg-no-repeat bg-center"
                  style={{backgroundImage: `url(${blok?.image.filename})`}}
                >
                  {/* <img
                    className=" h-auto w-full object-cover"
                    src={blok?.image.filename}
                    alt=""
                  /> */}
                  <div className="absolute top-[75%] w-full md:p-6 p-3">
                    <div className="justify-between bg-stroke flex flex-col md:p-6 p-3 rounded gap-2 min-h-[210px]">
                      <p className="text-small-regular text-muted-blue font-semi-bold">
                        {blok?.country}
                      </p>
                      <p className="text-SM font-bold capitalize text-dark1">
                        {blok?.city}
                      </p>
                      <p className="text-extra-small-regular w-2/3">
                        {blok?.address}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
        <img
          src={blok?.background.filename}
          alt="Events"
          className="hidden md:block  z-[1] mx-auto w-full -mt-[10%]"
        />
    </div>
  );
};
 
export default globalMapCards;