import React, { useState, useEffect } from "react";
import { storyblokEditable, useStoryblokApi } from "@storyblok/react";
import { Link } from "react-router-dom";

const ArticlesList = ({ blok }) => {
  const [categoryList, setCategoryList] = useState(["All"]);
  const [displayCount, setDisplayCount] = useState(6);
  const [selectedTag, setSelectedTag] = useState("All");
  const [blogStoryData, setBlogStoryData] = useState(null);
  const [filteredBlogData, setFilteredBlogData] = useState(null); // New state for filtered blogs

  const storyblokApi = useStoryblokApi();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await storyblokApi.get("cdn/stories/", {
          version: "draft",
          starts_with: "news/",
          resolve_relations: "globalHeader",
        });
        setBlogStoryData(data);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchBlogs();
  }, [storyblokApi]);

  useEffect(() => {
    if (!blogStoryData) return;

    const uniqueTags = [
      "All",
      ...new Set(blogStoryData.stories.flatMap((item) => item.tag_list)),
    ];
    setCategoryList(uniqueTags);
    setFilteredBlogData(blogStoryData); // Initialize filtered blogs with all blogs
  }, [blogStoryData]);

  useEffect(() => {
    if (!blogStoryData) return;

    if (selectedTag === "All") {
      setFilteredBlogData(blogStoryData);
    } else {
      const filteredData = blogStoryData.stories.filter((item) =>
        item.tag_list[0]?.includes(selectedTag)
      );
      setFilteredBlogData(filteredData);
    }
  }, [selectedTag, blogStoryData]);

  // Function to handle loading more blogs
  const handleLoadMore = () => {
    setDisplayCount(displayCount + 6);
  };

  const filterItem = (tag) => {
    setSelectedTag(tag);
    if (tag === "All") {
      setFilteredBlogData(blogStoryData?.stories); // Reset to all blogs if "All" tag is selected
      setDisplayCount(6);
    } else {
      const filteredData = blogStoryData?.stories?.filter((item) =>
        item.tag_list[0]?.includes(tag)
      );
      setFilteredBlogData(filteredData); // Update filtered blogs based on selected tag
      setDisplayCount(6);
    }
  };

  return (
    <div
      {...storyblokEditable(blok)}
      key={blok._uid}
      className="flex flex-col items-center px-20 pb-0 text-base leading-6 bg-white max-md:px-5 z-[11]"
    >
      <nav className=" w-full">
        <div className="sm:w-fit w-auto mx-auto flex justify-around items-center sm:overflow-hidden overflow-x-auto sm:pb-0 pb-2">
          {categoryList.map((tag) => (
            <button
              key={tag}
              className={`rounded-full mx-3 text-center font-medium ${
                selectedTag === tag ? "bg-primary" : "bg-secondary-text"
              }`}
              onClick={() => filterItem(tag)}
            >
              <p className="py-2 px-5 w-max"> {tag}</p>
            </button>
          ))}
        </div>
      </nav>
      <section className="mt-3">
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {filteredBlogData?.stories
            ? filteredBlogData?.stories?.slice(0, displayCount).map((blok) => (
                <Link key={blok._uid} to={blok.full_slug}>
                  <div className="flex flex-col overflow-hidden rounded-lg">
                    <div className="flex-shrink-0">
                      <img
                        className="h-auto w-full object-cover rounded-lg"
                        src={blok.content.body[0]?.image?.filename}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-1 flex-col justify-between py-7 font-aileron">
                      <div className="flex-1">
                        <p className="w-fit bg-gray2 px-4 py-1 rounded font-semi-bold text-extra-small-regular">
                          {new Date(blok.first_published_at).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <p className="mt-6 font-heavy text-XL line-clamp-2">
                          {blok.content.body[0].title}
                        </p>
                        <p className="mt-4 text-primary-text text-small-regular line-clamp-2">
                          {blok.content.body[0].description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            : filteredBlogData?.slice(0, displayCount).map((blok) => (
                <Link key={blok._uid} to={blok.full_slug}>
                  <div className="flex flex-col overflow-hidden rounded-lg">
                    <div className="flex-shrink-0">
                      <img
                        className="h-auto w-full object-cover rounded-lg"
                        src={blok.content.body[0]?.image?.filename}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-1 flex-col justify-between py-7 font-aileron">
                      <div className="flex-1">
                        <p className="w-fit bg-gray2 px-4 py-1 rounded font-semi-bold text-extra-small-regular">
                          {new Date(blok.first_published_at).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <p className="mt-6 font-heavy text-XL line-clamp-2">
                          {blok.content.body[0].title}
                        </p>
                        <p className="mt-4 text-primary-text text-small-regular line-clamp-2">
                          {blok.content.body[0].description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </section>

      {displayCount < filteredBlogData?.stories?.length && (
        <div className="flex justify-center mt-16">
          <button
            className="py-2 px-4 rounded-md border text-center font-semi-bold text-medium-regular"
            onClick={handleLoadMore}
          >
            Load more
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticlesList;
