import React from "react";
import { storyblokEditable } from "@storyblok/react";

const socialMedia = ({ blok }) => {
  return (
    <div
      className="lg:px-24 md:px-24 mb:px-8 container mx-auto font-aileron flex flex-col relative px-4 pb-28"
      {...storyblokEditable(blok)}
      key={blok?._uid}
    >
      <div className="z-[3] flex justify-center gap-16 md:flex-nowrap flex-wrap content-center items-center">
        {blok?.socialMediaCards.map((blok) => {
          return (
            <>
              <div className="flex  flex-col justify-center items-center w-52 text-center">
                <img
                  className="object-cover max-w-[120px]"
                  src={blok?.Icon.filename}
                  alt=""
                />
                <div className="text-large-regular text-white mt-5">
                  {blok?.title}
                </div>

                {blok?.linktype === "link:" && (
                  <>
                      <a
                        href={ `https://${blok?.Link?.cached_url}`}
                        target= "_blank"
                        rel= "noopener noreferrer"
                         className="text-large-regular text-white"
                      >
                      {blok?.Link?.cached_url}
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      className="text-large-regular text-white"
                      href={blok?.link2?.cached_url}
                    >
                      {blok?.link2?.cached_url}
                    </a>
                  </>
                )}

                {blok?.linktype === "mailto:" && (
                  <>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      className="text-large-regular text-white"
                      href={"mailto:" + blok?.Link?.cached_url}
                    >
                      {blok?.Link?.cached_url}
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      className="text-large-regular text-white"
                      href={"mailto:" + blok?.link2?.cached_url}
                    >
                      {blok?.link2?.cached_url}
                    </a>
                  </>
                )}

                {blok?.linktype === "tel:" && (
                  <>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      className="text-large-regular text-white"
                      href={"tel:" + blok?.Link?.cached_url}
                    >
                      {blok?.Link?.cached_url}
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      className="text-large-regular text-white"
                      href={"tel:" + blok?.link2?.cached_url}
                    >
                      {blok?.link2?.cached_url}
                    </a>
                  </>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default socialMedia;
