import React, { useState, useEffect } from "react";
import { storyblokEditable, useStoryblokApi } from "@storyblok/react";
import { Link } from "react-router-dom";

const RecentArticlesCards = ({ blok }) => {
  const [blogStoryData, setBlogStoryData] = useState(null);
  const storyblokApi = useStoryblokApi();
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await storyblokApi.get("cdn/stories/", {
          version: "draft",
          starts_with: "news/",
          resolve_relations: "globalHeader",
        });
        setBlogStoryData(data);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchBlogs();
  }, [storyblokApi]);

  return (
    <div
      className="mt-[100px] flex flex-col items-center p-20 pb-0 text-base leading-6 bg-white max-md:px-5 z-[11]"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <div className="mt-10 text-large-regular font-aileron font-bold  text-primary md:text-center text-left md:mr-0 mr-auto">
        {blok?.Title}
      </div>
      <div className="mt-2 text-4XL font-heavy text-ink-blue font-aileron leading-10  text-teal-950 md:w-1/2 md:text-center text-left md:mr-0 mr-auto">
        {blok?.Heading}
      </div>
      <div className="mt-8 leading-6 text-primary-text font-aileron text-medium-regular font-regular md:w-1/2 md:text-center text-left md:mr-0 mr-auto">
        {blok?.Description}
      </div>
      <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        {blogStoryData?.stories?.slice(0, 3).map((blok) => (
          <Link key={blok._uid} to={blok.full_slug}>
            <div className="flex flex-col overflow-hidden rounded-lg">
              <div className="flex-shrink-0">
                <img
                  className="h-auto w-full object-cover rounded-lg"
                  src={blok.content.body[0]?.image?.filename}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between py-7 font-aileron">
                <div className="flex-1">
                  <p className="w-fit bg-gray2 px-4 py-1 rounded font-semi-bold text-extra-small-regular">
                    {new Date(blok?.first_published_at).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </p>
                  <p className="mt-6 font-heavy text-XL line-clamp-2">
                    {blok.content.body[0].title}
                  </p>
                  <p className="mt-4 text-primary-text text-small-regular line-clamp-2">
                    {blok.content.body[0].description}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="mt-20 mb-1 bg-primary h-[2px] w-full" />
    </div>
  );
};

export default RecentArticlesCards;
