import React,{useState} from "react";
import { storyblokEditable } from "@storyblok/react";

const ContactUs = ({ blok }) => {
  const [formData, setFormData] = useState({
    SingleLine: "",
    Email: "",
    PhoneNumber_countrycode: "",
    MultiLine: "",
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "MultiLine" && value.length > 400) {
      // If the textarea content exceeds 400 characters, trim it to 400 characters
      setFormData({
        ...formData,
        [name]: value.slice(0, 400),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  return (
    <div
      className="mt-[200px] flex flex-col items-center p-20 text-base leading-6 bg-gray1 max-md:px-5 z-[11]"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <div className="mt-10 text-large-regular font-aileron font-bold text-center text-primary">
        {blok?.Title}
      </div>
      <div className="mt-2 text-4XL font-heavy text-ink-blue font-aileron leading-10 text-center text-teal-950 max-md:max-w-full">
        {blok.Heading}
      </div>
      <div className="shrink-0 mt-4 h-0.5 bg-primary w-[170px]" />
      <div className="mt-8 leading-6 text-center text-primary-text font-aileron text-medium-regular font-regular max-md:max-w-full">
        {blok.Description}
      </div>
      <iframe
        name="submit_target"
        title="Form Submission Status"
        className="Success-Message"
        style={{
          display: "none",
          width: "100%",
          height: "16rem",
        }}
        onLoad={() => {
          document.querySelector("#form").style.display = "none";
          document.querySelector(".Success-Message").style.display = "block";
        }}
      ></iframe>
      <form
        className="container"
        action="https://forms.zohopublic.eu/lewisinternationalsearchltd/form/Connectwithus/formperma/5pGVDdlG2UbTxlfwsdKaav3BeH7Ob0NksMcLotyO7Kk/htmlRecords/submit"
        name="form"
        id="form"
        method="POST"
        accept-charset="UTF-8"
        enctype="multipart/form-data"
        target="submit_target"
      >
        <input type="hidden" name="zf_referrer_name" value="" />
        <input type="hidden" name="zf_redirect_url" value="" />
        <input type="hidden" name="zc_gad" value="" />
        <div className="flex gap-5 mt-16 max-w-full max-md:flex-wrap max-md:mt-10 sm:flex-wrap">
          <div className="flex flex-col flex-1 ">
            <div className="font-semi-bold text-dark1 text-medium-regular">
              Your name
            </div>

            <input
              className="justify-center items-start py-3 pr-4 pl-5 mt-2.5 text-gray-400 bg-white rounded-md border border-solid border-zinc-200 max-md:pr-5"
              type="text"
              name="SingleLine"
              value={formData?.SingleLine}
              onChange={handleInputChange}
              fieldType={1}
              maxlength="255"
              placeholder="John Smith"
              required
            />
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-semi-bold text-dark1 text-medium-regular">
              Your email
            </div>
            <input
              className="justify-center items-start py-3 pr-4 pl-5 mt-2.5 text-gray-400 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-200 max-md:pr-5"
              type="text"
              maxlength="255"
              name="Email"
              value={formData?.Email}
              onChange={handleInputChange}
              fieldType={9}
              placeholder="john.smith@company.com"
              required
            />
          </div>
          <div className="flex flex-col flex-1">
            <div className="font-semi-bold text-dark1 text-medium-regular">
              Your phone
            </div>

            <input
              className="justify-center items-start py-3 pr-4 pl-5 mt-2.5 text-gray-400 bg-white rounded-md border border-solid border-zinc-200 max-md:pr-5"
              type="text"
              compname="PhoneNumber"
              name="PhoneNumber_countrycode"
              phoneFormat="1"
              isCountryCodeEnabled="false"
              maxlength="20"
              value={formData.PhoneNumber_countrycode}
              onChange={handleInputChange}
              fieldType={11}
              id="international_PhoneNumber_countrycode"
              placeholder="+44 (0) 1234 56789"
              required
            />
          </div>
        </div>
        <div className="mt-6 font-semi-bold text-dark1 text-medium-regular max-md:max-w-full">
          Message
        </div>
        <textarea
          className="flex flex-1 justify-center items-start px-5 pt-5 pb-7 mt-2.5 max-w-full text-gray-400 bg-white rounded-md border border-solid border-zinc-200 w-full"
          name="MultiLine"
          maxlength="65535"
          placeholder="Type your message"
          value={formData.MultiLine}
          onChange={handleInputChange}
          required
        ></textarea>
        <div className="self-end mt-4  text-sm text-right text-gray-500 max-md:mr-2.5">
        {400 - formData.MultiLine.length} / 400
        </div>
        <div className=" flex justify-center items-start px-5 pt-5 pb-7 mt-2.5">
          <button
            className="justify-center items-center px-7 py-3.5 mt-6 font-semi-bold text-center bg-primary rounded-md text-teal-950"
            type="submit"
          >
            Send message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
