import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Drawer from "./Drawer";
import { storyblokEditable } from "@storyblok/react";

const Navigation = ({ ...blok }) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLinkClick = (item) => {
    setSelectedLink(item._uid === selectedLink ? null : item._uid);
    handleItemClick();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = () => {
    setIsDropdownOpen(false);
    toggleDrawer();
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div {...storyblokEditable(blok.blok)} key={blok.blok?._uid}>
      <header
        className={`w-full absolute z-[90] ${
          blok?.color?.Global?.[0]?.BackgroundColor
            ? blok?.color?.Global?.[0]?.BackgroundColor
            : "text-white"
        }`}
      >
        <nav className="container mx-auto pt-8 mb-4">
          <div className="grid  md:grid-cols-4 gap-3">
            <div className="flex justify-center x0:px-4 xs:px-4 items-center">
              <Link to="/" className="flex items-center">
                <img
                  className="max-w-[100%] xs:max-w-[50%] sm:max-w-[80%] md:max-w-[100%] h-auto"
                  src={`${
                    blok?.color?.Global?.[0]?.BackgroundColor 
                      ? "../images/Lewis_logo_Bright.png"
                      : "../images/Lewis_logo.png"
                  }`}
                  alt="lewis logo"
                />
              </Link>
              <div className="ml-auto md:hidden">
                <button
                  onClick={toggleDrawer}
                  className="flex items-center px-3 py-2"
                  type="button"
                >
                  <svg
                    className="w-10 h-11 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke={
                        blok?.color?.Global?.[0]?.BackgroundColor
                          ? "#000"
                          : "#fff"
                      }
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="M5 7h14M5 12h14M5 17h14"
                    />
                  </svg>
                </button>
                <Drawer
                  isOpen={isDrawerOpen}
                  onClose={toggleDrawer}
                  megaMenuButton={blok.blok?.Global}
                >
                  <ul>
                    {blok.blok.length !== 0 &&
                      blok.blok?.Global[0].links.map((item) => (
                        <li key={item._uid} className="relative">
                          <Link
                            to={item.Link.url}
                            onClick={() => {
                              item.Navigation.length !== 0
                                ? toggleDropdown()
                                : handleLinkClick(item);
                            }}
                            className={`block font-aileron text-large-regular ${
                              !isDropdownOpen ? "font-regular" : "font-heavy"
                            } py-2 md:p-2 lg:px-4`}
                          >
                            <div className="flex justify-between items-center">
                              {item.LinkName}
                              {item.Navigation.length !== 0 &&
                                (isDropdownOpen ? (
                                  <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M20.6246 17.5998C20.3996 17.5998 20.2121 17.5248 20.0246 17.3748L11.9996 9.4623L3.97461 17.3373C3.63711 17.6748 3.11211 17.6748 2.77461 17.3373C2.43711 16.9998 2.43711 16.4748 2.77461 16.1373L11.3996 7.6623C11.7371 7.3248 12.2621 7.3248 12.5996 7.6623L21.2246 16.1373C21.5621 16.4748 21.5621 16.9998 21.2246 17.3373C21.0746 17.4873 20.8496 17.5998 20.6246 17.5998Z"
                                      fill="white"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    onClick={toggleDropdown}
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.9996 17.6002C11.7746 17.6002 11.5871 17.5252 11.3996 17.3752L2.77461 8.9002C2.43711 8.5627 2.43711 8.0377 2.77461 7.7002C3.11211 7.3627 3.63711 7.3627 3.97461 7.7002L11.9996 15.5377L20.0246 7.6252C20.3621 7.2877 20.8871 7.2877 21.2246 7.6252C21.5621 7.9627 21.5621 8.4877 21.2246 8.8252L12.5996 17.3002C12.4121 17.4877 12.2246 17.6002 11.9996 17.6002Z"
                                      fill="white"
                                    />
                                  </svg>
                                ))}
                            </div>
                            {item.Navigation.length !== 0 && isDropdownOpen && (
                              <div className="mt-2">
                                <div
                                  className="py-1 text-[#000000]"
                                  role="menu"
                                  aria-orientation="vertical"
                                  aria-labelledby="options-menu"
                                >
                                  {item.Navigation.map((item) => (
                                    <Link
                                      key={item._uid}
                                      onClick={handleItemClick}
                                      to={item.Link.url}
                                      className=" block px-4 py-2  text-medium-regular font-regular  hover:font-bold text-white"
                                      role="menuitem"
                                    >
                                      {item.LinkName}
                                      {item.url}
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Link>

                          {/* {location.pathname === item.Link.url && <div className="absolute bottom-1 left-8 w-12 h-px bg-light-green"></div>} */}
                        </li>
                      ))}
                  </ul>
                </Drawer>
              </div>
            </div>
            <div className="sm:hidden mb:hidden x0:hidden xs:hidden md:flex sm:col-span-3 md:col-span-2 flex justify-center items-center">
              <div className="justify-center  md:flex-grow md:flex md:items-center">
                <ul className="flex flex-row lg:gap-4">
                  {blok.blok.length !== 0 &&
                    blok.blok?.Global[0].links.map((item) => (
                      <li key={item._uid} className="relative">
                        <Link
                          to={item.Link.url}
                          onMouseEnter={() => {
                            item.Navigation.length !== 0
                              ? toggleDropdown()
                              : handleLinkClick(item);
                          }}
                          onMouseLeave={() => {
                            setIsDropdownOpen(false);
                          }}
                          className="block font-aileron text-medium-regular font-semi-bold px-2 py-1 md:p-2 lg:px-2"
                        >
                          <div className="flex items-center">
                            {item.LinkName}
                            {item.Navigation.length !== 0 && (
                              <svg
                                onMouseEnter={toggleDropdown}
                                onMouseLeave={() => {
                                  setIsDropdownOpen(false);
                                }}
                                className="w-4 h-4 ml-1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9.293 14.707a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 12.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                          </div>
                          {item.Navigation.length !== 0 && isDropdownOpen && (
                            <div className="origin-top-right absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <div
                                className="py-1 text-[#000000]"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                              >
                                {item.Navigation.map((item) => (
                                  <Link
                                    key={item._uid}
                                    onClick={handleItemClick}
                                    to={item.Link.url}
                                    className="block px-4 py-2 text-sm text-medium-regular  hover:font-bold"
                                    role="menuitem"
                                  >
                                    {item.LinkName}
                                    {item.url}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          )}
                        </Link>

                        {location.pathname === item.Link.url && (
                          <div className="absolute bottom-1 left-2 w-12 h-px bg-light-green"></div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="sm:hidden mb:hidden x0:hidden xs:hidden md:flex justify-center items-baseline">
              {blok.blok.length !== 0 &&
                blok.blok?.Global[0]?.megaMenuButton.map((blok) => (
                  <>
                    <Link
                      key={blok?._uid}
                      to={blok?.ButtonNavigationLink.cached_url}
                      target={
                        blok?.LinkTarget === "same_page"
                          ? "_self"
                          : blok?.LinkTarget === "new_page"
                          ? "_blank"
                          : "_self"
                      }
                    >
                      <button
                        className={`border font-aileron text-medium-regular font-semi-bold rounded-md  items-center sm:px-2 md:px-7 sm:py-2 md:py-3 `}
                        // style={{
                        //   border:
                        //     blok?.buttonBackgroundColor === "#ffff"
                        //       ? "1px solid rgb(255, 255, 255)"
                        //       : "none",
                        //   color:
                        //     blok?.buttonBackgroundColor === "#ffff"
                        //       ? "#ffff"
                        //       : "inherit",
                        //   backgroundColor:
                        //     blok?.buttonBackgroundColor === "#ffff"
                        //       ? "#ffffff00"
                        //       : blok.blok?.buttonBackgroundColor,
                        // }}
                      >
                        {blok?.ButtonTitle}
                      </button>
                    </Link>
                  </>
                ))}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navigation;
