import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Page from "./Component/Page.js";
import reportWebVitals from './reportWebVitals';
import Hero from './Component/Hero.jsx';
import WorldMap from './Component/WorldMap.jsx';
import ContentBlok from './Component/ContentBlok.jsx';
import Banner from './Component/Banner.jsx';
import FullWidthHr from './Component/FullWidthHr.jsx';
import Events from './Component/Events.jsx';
import ConnectForm from './Component/connectForm.jsx';
import sectors from './Component/sectors.jsx';
import globalMapCards from './Component/globalMapCards.jsx';
import ContactUs from './Component/ContactUs.jsx';
import socialMedia from './Component/socialMedia.jsx';
import { BrowserRouter } from 'react-router-dom';
import HeroBanner from './Component/HeroBanner.jsx';
import RecentArticlesCards from './Component/RecentArticlesCards.jsx';
import ArticlesList from './Component/ArticlesList.jsx';
import SingleBanner from './Component/SingleBanner.jsx';
import ContentText from './Component/Text.jsx';
import GlobalHeader from './Component/Global/globalHeader.jsx';
import RelatedArticlesCards from './Component/RelatedArticlesCards.jsx';
import SocialShareArticleButton from './Component/SocialShareArticleButton.jsx';
import BlogArticle from './Component/BlogArticle.js';

const accessToken = process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN;

const components = {
  globalHeader:GlobalHeader,
  page: Page,
  BlogArticle:BlogArticle,
  hero: Hero,
  HeroBanner: HeroBanner,
  ContentBlok:ContentBlok,
  globalMapCards:globalMapCards,
  sectors:sectors,
  worldmap:WorldMap,
  Banner:Banner,
  SingleBanner:SingleBanner,
  Events:Events,
  ConnectForm:ConnectForm,
  FullWidthHr:FullWidthHr,
  ContactUs:ContactUs,
  socialMedia:socialMedia,
  Text: ContentText,
  RecentArticlesCards:RecentArticlesCards,
  RelatedArticlesCards:RelatedArticlesCards,
  ArticlesList:ArticlesList,
  SocialShareArticleButton:SocialShareArticleButton,
};

storyblokInit({
  accessToken: accessToken,
  use: [apiPlugin],
  resolveRelations: "global_header", 
  components,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
        <App />
     </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
