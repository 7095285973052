import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { Link } from "react-router-dom";

const Events = ({ blok }) => {
  return (
    <div
      className="container m-auto"
      {...storyblokEditable(blok)}
      key={blok?._uid}
    >
      <div className="font-aileron flex flex-col items-center   relative z-[3]">
        <img
          src="./images/Blurred_shapes5.png"
          alt="Events"
          className="absolute left-[-10%] top-0 z-[-1]"
        />
        <div className="flex flex-col item-start  content-center flex-wrap my-2 gap-2">
          <h1 className=" font-heavy text-white leading-9 text-4XL">
            {blok?.title}
          </h1>
          {blok?.title && (
            <div className="bg-light-green h-[2px] w-[50%] md:w-[9rem] mt-4 md:mx-auto" />
          )}
        </div>
        <div className="mt-8 leading-7 md:text-center text-left text-white md:max-w-full font-lora text-large-regular">
          {blok?.subtitle}
        </div>
        <div className="self-stretch mt-8 w-full md:px-5 px-0 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-w-[1300px] mx-auto justify-center">
            {/* map */}
            {blok?.eventcard.map((blok, index) => {
              return (
                <>
                  <div
                    key={blok?._uid}
                    className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
                  >
                    <div
                      className={`flex flex-col grow self-stretch px-9 py-8 w-full text-base text-center bg-${blok?.color} rounded-lg text-teal-950 max-md:px-5  md:mt-10  md:max-w-full mt-5`}
                      style={{ backgroundColor: blok?.color }}
                    >
                      <div className="text-XL font-heavy font-aileron">
                        {blok?.title}
                      </div>
                      <div className="mt-4 leading-6 font-aileron text-medium-regular font-regular">
                        {blok?.subtitle}
                      </div>
                      {blok.button.map((blok) => {
                        return (
                          <>
                            <Link
                              key={blok?._uid}
                              to={blok?.ButtonNavigationLink.cached_url}
                              target={
                                blok?.LinkTarget === "same_page"
                                  ? "_self"
                                  : blok?.LinkTarget === "new_page"
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              <button
                                className={`justify-center self-center px-7 py-3.5 mt-7 rounded-md border border-solid leading-[150%] max-md:px-5 font-aileron text-medium-regular font-regular ${
                                  blok.buttonBackgroundColor
                                    ? blok.buttonBackgroundColor
                                    : "text-white border-[1px] border-current"
                                } z-[2]`}
                              >
                                {blok.ButtonTitle}
                              </button>
                            </Link>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
            {/* map end */}
          </div>
        </div>
        <div className="mt-8 leading-7 text-center text-white max-md:max-w-full font-lora text-large-regular">
          {blok?.secondsubtitle}
        </div>
        {blok?.button.map((blok) => {
          return (
            <>
              <Link
                key={blok?._uid}
                to={blok?.ButtonNavigationLink.cached_url}
                target={
                  blok?.LinkTarget === "same_page"
                    ? "_self"
                    : blok?.LinkTarget === "new_page"
                    ? "_blank"
                    : "_self"
                }
              >
                <button
                  className={`flex p-3 cursor-pointer justify-center items-center rounded font-aileron font-medium-regular font-semi-bold mt-8 px-12`}
                  style={{
                    border:
                      blok?.buttonBackgroundColor === "#ffff"
                        ? "1px solid rgb(255, 255, 255)"
                        : "none",
                    color:
                      blok?.buttonBackgroundColor === "#ffff"
                        ? "#ffff"
                        : "inherit",
                    backgroundColor:
                      blok?.buttonBackgroundColor === "#ffff"
                        ? "#ffffff00"
                        : blok?.buttonBackgroundColor,
                  }}
                >
                  {blok.ButtonTitle}
                </button>
              </Link>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Events;
