import React, { useState, useEffect } from "react";
import { storyblokEditable, renderRichText } from "@storyblok/react";
import { Link } from "react-router-dom";

const ContentBlok = ({ blok }) => {
  const renderedRichText = renderRichText(blok.description);
  const [isTextCenter, setIsTextCenter] = useState(false);

  // Function to check if the HTML string contains the richtextCenter class
  useEffect(() => {
    const checkForTextCenter = () => {
      setIsTextCenter(renderedRichText.includes("richtextCenter"));
    };

    checkForTextCenter();
  }, [renderedRichText]);

  return (
    <div
      className="z-[20] sm:relative container mx-auto lg:px-24 md:px-24 mb:px-8 "
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <div
        className={
          isTextCenter
            ? "richtext-center richtext text-white"
            : "richtext text-white"
        }
        dangerouslySetInnerHTML={{ __html: renderedRichText }}
      />
      <div
        className="flex x0:gap-0 x0:flex-col mb:flex-row mb:gap-10"
        style={{
          justifyContent:
            blok?.ButtonAlign === "center"
              ? "center"
              : blok?.ButtonAlign === "right"
              ? "right"
              : "flex-start",
        }}
      >
        {blok.body.map((blok) => (
          <Link
            key={blok?._uid}
            to={blok?.ButtonNavigationLink.cached_url}
            target={
              blok?.LinkTarget === "same_page"
                ? "_self"
                : blok?.LinkTarget === "new_page"
                ? "_blank"
                : "_self"
            }
          >
            <button
              className="flex p-3 cursor-pointer items-center rounded font-aileron font-medium-regular font-semi-bold mt-8 px-12"
              style={{
                border:
                  blok?.buttonBackgroundColor === "#ffff"
                    ? "1px solid rgb(255, 255, 255)"
                    : "none",
                color:
                  blok?.buttonBackgroundColor === "#ffff" ? "#ffff" : "inherit",
                backgroundColor:
                  blok?.buttonBackgroundColor === "#ffff"
                    ? "#ffffff00"
                    : blok?.buttonBackgroundColor,
              }}
            >
              {blok.ButtonTitle}
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ContentBlok;
