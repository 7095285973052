import React from "react";
import { storyblokEditable } from "@storyblok/react";

const SocialShareArticleButton = ({ blok }) => {
  // Function to handle sharing URL on Facebook
  const shareOnFacebook = () => {
    const url = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  // Function to handle sharing URL on LinkedIn
  const shareOnLinkedIn = () => {
    const url = window.location.href;
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
      "_blank"
    );
  };

  return (
    <>
      {blok?.ShareButton && (
        <div
          className="font-aileron flex bg-gray1 text-primary-text md:justify-end justify-start"
          {...storyblokEditable(blok)}
          key={blok?._uid}
        >
          <div className="flex m-2">
            <p className="font-aileron text-primary-text text-medium-regular font-semi-bold">
              Share this post
            </p>
            <button className="mx-5" onClick={shareOnFacebook} target="_blank" rel="noopener noreferrer">
              <img
                src="./images/facebook-original.svg" 
                alt="Share on Facebook"
                className=""
              />
            </button>
            <button className="" onClick={shareOnLinkedIn} target="_blank" rel="noopener noreferrer">
              <img
                src="./images/linkedin-original.svg"
                alt="Share on LinkedIn"
                className=""
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SocialShareArticleButton;
