import React from 'react';
import "./style.css"

const GradientLayout = ({ children }) => {
//  bg-gradient-to-b from-[#000000] to-[#012030]
  return (
    <div style={{background:" linear-gradient(180deg, #000 0%, #012030 100%)"}} >
    <div className="min-h-screen max-w-full overflow-hidden"  >
      {children}
    </div>  
    </div>
  );
};

export default GradientLayout;
