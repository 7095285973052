import { storyblokEditable } from "@storyblok/react";
import { renderRichText } from "@storyblok/react";

const ContentText = ({ blok }) => {
  const renderedRichText = renderRichText(blok?.text);
  return (
    <div
      className={` w-full`}
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      {/* <div className="contentText container mx-auto py-12">{render(blok.text)}</div> */}
      <div
          className={`richtext ${blok.BackgroundColor} contentText  mx-auto mt-28 py-12 pt-24 px-[10%]`}
          dangerouslySetInnerHTML={{ __html: renderedRichText }}
        />
    </div>
  );
};

export default ContentText;
