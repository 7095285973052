import { storyblokEditable } from "@storyblok/react";
import React from "react";

const Banner = ({ blok }) => {
  return (
    <>
      <div
        className="py-5 flex justify-center  relative"
        {...storyblokEditable(blok)}
        key={blok._uid}
      >
             <img src="./images/Blurredshapes4.png" alt="Events" className="absolute right-0 top-0" />
        <img src={blok.Banner_desktop.filename} className="x0:hidden sm:block" alt="Banner_desktop" />
        <img src={blok.Banner_mobile.filename} className="sm:hidden x0:block" alt="Banner_mobile" />
      </div>
    </>
  );
};

export default Banner;
