import React from "react";
import { Link } from "react-router-dom";

const Footer = ({blok}) => {
  return (
    <footer
      className=" font-aileron text-center text-white flex flex-col flex-wrap flex-start md:w-[auto] bg-no-repeat pt-6 bg-center"
      style={{
        backgroundImage: `url(${blok?.Global?.[0]?.background.filename})`,
        backgroundSize: "cover",
      }}
    >
      <div className="xl:mt-28 sm:w-[340px] xs:w-4/5 mb-72 md:ml-28 sm:ml-14 ml-7">
        <img
          className=" "
          src={blok?.Global?.[0]?.footerlogo.filename}
          alt="lewise international search"
        />
        <p className="font-lora text-3XL text-left md:w-auto  w-[70%] mt-8">
          {blok?.Global?.[0]?.footertext}
        </p>
      </div>
      <div className="md:ml-28 sm:ml-14 ml-7 font-aileron text-center text-white flex flex-wrap flex-start gap-3 pb-12 mt-24 md:w-[auto] sm:w-[80%]">
        {blok?.Global?.[0]?.Footer?.map((item, index) => {
          return (
            <div key={item?._uid} className="flex md:gap-3 xs:gap-2">
              <Link
                className={`${index === 0 ? "" : "underline"} font-aileron`}
                to={item.Link.url}
                key={index}
              >
                {item.LinkName}
              </Link>
              {index === 2 ? "" : "|"}
            </div>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
