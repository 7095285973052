import React, { useState, useEffect } from "react";
import { storyblokEditable } from "@storyblok/react";
import FadingImageSlider from "./fading.js";

const Hero = ({ blok }) => {
  let images = blok?.Card[0]?.Card;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // Change image every 10 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      className="  bg-white-half sm:pb-60 pb-28"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <img
        className="absolute z-10 opacity-60"
        alt="Blurred_shapes1"
        src="../images/Blurred_shapes1.png"
      />
      <div className="  mx-auto ">
        <div
          className="w-full h-full bg-cover min-h-screen bg-no-repeat bg-center"
          //  style={{ backgroundImage: `url(${blok.BackgroundImg.filename})` }}
        >
          <div className=" mx-auto relative w-[100vw] h-[100vh] ">
            <FadingImageSlider images={blok.Card[0].Card} />
            <div
              className={` container text-center ${
                blok.AlignText === "center" ? "items-center" : "items-start"
              }   md:w-96 sm:w-96 mb:w-96 xs:w-3/4  absolute bottom-0 mb-40 flex flex-col gap-6  md:ml-20 ml-10  ${
                blok.AlignText === "left"
                  ? "left-0"
                  : blok.AlignText === "center"
                  ? "left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  : "right-0"
              }`}
            >
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={`z-[2] slidetext ${
                      currentImageIndex === index ? "active" : ""
                    }`}
                  >
                    <h2 className="text-3XL text-regular font-lora text-white text-start">
                      {image?.Title}
                    </h2>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9996 17.6002C11.7746 17.6002 11.5871 17.5252 11.3996 17.3752L2.77461 8.9002C2.43711 8.5627 2.43711 8.0377 2.77461 7.7002C3.11211 7.3627 3.63711 7.3627 3.97461 7.7002L11.9996 15.5377L20.0246 7.6252C20.3621 7.2877 20.8871 7.2877 21.2246 7.6252C21.5621 7.9627 21.5621 8.4877 21.2246 8.8252L12.5996 17.3002C12.4121 17.4877 12.2246 17.6002 11.9996 17.6002Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
