import { storyblokEditable } from "@storyblok/react";

const SingleBanner = ({ blok }) => {
  return (
    <div
      className=" mb-24 bg-white-half"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      
      <div className="  mx-auto ">
        <div
          className="w-full h-full"
         // style={{ backgroundImage: `url(${blok.BackgroundImg.filename})` }}
        >
          <img
        className="w-full h-full"
        alt="Blurred_shapes1"
        src={blok.BackgroundImg.filename}
      />
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;
