import React, { useState, useEffect } from "react";
import { storyblokEditable, useStoryblokApi } from "@storyblok/react";
import { renderRichText } from "@storyblok/react";

const BlogArticle = ({ blok }) => {
  const [blogdata, setBlogData] = useState(null);
  const renderedRichText = renderRichText(blok?.content);

  const shareOnFacebook = () => {
    const url = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  // Function to handle sharing URL on LinkedIn
  const shareOnLinkedIn = () => {
    const url = window.location.href;
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
      "_blank"
    );
  };
  const url = window.location.pathname.slice(1);
  const storyblokApi = useStoryblokApi();

  useEffect(() => {
    const fetchBlogdata = async () => {
      try {
        const { data } = await storyblokApi.get("cdn/stories", {
          version: "draft",
          starts_with: url,
        });
        setBlogData(data.stories[0]);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };
    fetchBlogdata();
  }, [storyblokApi, url]);

  (function () {
    // DON'T EDIT BELOW THIS LINE
    var d = document,
      s = d.createElement("script");
    s.src = "https://localhost-3000-xdat2argyl.disqus.com/embed.js";
    s.setAttribute("data-timestamp", +new Date());
    (d.head || d.body).appendChild(s);
  })();
  return (
    <>
      <div
        className="mt-[100px] flex flex-col p-20 pb-0 text-base leading-6 bg-white max-md:px-5 z-[11] font-aileron"
        {...storyblokEditable(blok)}
        key={blok?._uid}
      >
        <div className="mt-2 text-4XL font-heavy text-ink-blue leading-10  text-teal-950 text-left mr-auto">
          {blok?.title}
        </div>
        {blok?.title && (
          <div className="bg-light-green h-[2px] w-[12rem] md:w-[9rem] bottom-5 mt-4" />
        )}
        <div className="mt-7 flex items-center flex-wrap sm:gap-8 gap-4">
          {blok?.author && (
            <div className="flex sm:w-max w-full text-medium-regular text-primary-text font-semi-bold text-left ">
              <img
                src="../images/avatar.svg"
                alt="calender"
                className="mr-3 w-6 h-6"
              />
              By {blok?.author}
            </div>
          )}
          <div className="flex w-max text-medium-regular text-primary-text font-semi-bold text-left">
            <img
              src="../images/calender1.svg"
              alt="calender"
              className="mr-3"
            />
            {new Date(blogdata?.first_published_at).toLocaleDateString(
              "en-US",
              {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }
            )}
          </div>
        </div>
        <div className="my-8 leading-6 text-primary-text text-medium-regular font-regular text-left">
          {blok?.description}
        </div>
        <div
          className="w-full h-full"
          // style={{ backgroundImage: `url(${blok.BackgroundImg.filename})` }}
        >
          <img
            className="w-full h-full rounded-2xl"
            alt="blogImg"
            src={blok?.image.filename}
          />
        </div>
        <div
          className={`blogImage contentText mx-auto pt-12 richtext`}
          dangerouslySetInnerHTML={{ __html: renderedRichText }}
        />
        <div className="flex  text-primary-text justify-between flex-wrap my-8 gap-[30px]">
          <div className="flex items-center flex-wrap gap-4">
            {blogdata?.tag_list?.length &&
              blogdata?.tag_list?.map((tag) => (
                <p className="px-3 py-1 text-extra-small-regular text-dark1 font-medium bg-dark8 rounded-full w-max">
                  {tag}
                </p>
              ))}
          </div>
          <div className="flex m-2">
            <p className="text-primary-text text-medium-regular font-semi-bold">
              Share this post
            </p>
            <button
              className="mx-5"
              onClick={shareOnFacebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../images/facebook-original.svg"
                alt="Share on Facebook"
                className=""
              />
            </button>
            <button
              className=""
              onClick={shareOnLinkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../images/linkedin-original.svg"
                alt="Share on LinkedIn"
                className=""
              />
            </button>
          </div>
        </div>
        <div id="disqus_thread"></div>
      </div>
    </>
  );
};

export default BlogArticle;
