import React, { useEffect, useState } from "react";
import "./App.css";
import { StoryblokComponent, useStoryblokApi } from "@storyblok/react";
import Layout from "./Component/Layout/Layout";
import GradientLayout from "./Component/Theme/gradient";
import Footer from "./Component/Global/Footer";
import { useLocation } from "react-router-dom";
import GlobalHeader from "./Component/Global/globalHeader";
import NotFound from "./Component/Global/404NotFound";

function App() {
  const location = useLocation();
  const storyblokApi = useStoryblokApi();
  const [storyData, setStoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname === "/news"
      ? "/news-list": window.location.pathname.replace("/", "");

  useEffect(() => {}, [location]);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const { data } = await storyblokApi.get("cdn/stories/" + slug, {
          version: "draft",
          resolve_relations: "globalHeader",
        });
        setStoryData(data);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStory();
  }, [storyblokApi, slug]);
  
useEffect(() => {
  const timeout = setTimeout(() => {
    setLoading(false);
  }, 2000); // 2 seconds

  return () => clearTimeout(timeout);
}, []);

  if (!storyData?.story?.content ) {
    return (
      <div
        className=" min-h-screen flex items-center justify-center"
        style={{
          background: " linear-gradient(180deg, #000 0%, #012030 100%)",
        }}
      >
       { loading?<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
        :<NotFound/>}
      </div>
    );
  }

  const findComponentByName = (name) => {
    return storyData.rels.find((component) => component.name === name);
  };

  return (
    <>
      <GradientLayout>
        <Layout>
          {storyData && storyData?.rels.length !== 0 && (
            <GlobalHeader
              blok={findComponentByName("Header")?.content}
              color={findComponentByName("Color")?.content}
            />
          )}
          {storyData.story.content.body[0].component === "HeroBanner" ||
          storyData.story.content.body[0].component === "hero" ? (
            <img
              className="absolute right-0 z-[10] sm:top-0 top-[49rem]"
              src="../images/Blurred_shapes2.png"
              alt="Blurred_shapes2"
            />
          ) : (
            ""
          )}
          <StoryblokComponent blok={storyData?.story?.content} />
          {storyData &&
            storyData?.rels.length !== 0 &&
            findComponentByName("Footer") && (
              <Footer blok={findComponentByName("Footer")?.content} />
            )}
        </Layout>
      </GradientLayout>
    </>
  );
}

export default App;
