import { storyblokEditable } from "@storyblok/react";
import React from "react";

const FullWidthHr = ({ blok }) => {
  return (
    <>
      <div
        className="container m-auto"
        {...storyblokEditable(blok)}
        key={blok._uid}
      >
        <div className={`h-0.5 w-full`} style={{ backgroundColor: blok?.color }} />
      </div>
    </>
  );
};

export default FullWidthHr;
