import React, { useState, useEffect } from "react";

const FadingImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // Change image every 5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="header-wrapper w-full h-[120vh] ">
      <div className="image-slider">
        {images.map((image, index) => {
          return (
            <div
              key={index}
              className={`z-[2] slide ${
                currentImageIndex === index ? "active" : ""
              }`}
            >
              {/* <img src={image} alt={`Image ${index + 1}`} /> */}
              <div
                className="w-full min-h-screen bg-no-repeat bg-top z-[2]"
                style={{
                  backgroundImage: `url(${image.BackgroundImg.filename})`,
                }}
              ></div>
              {/* <p className="text-white bg-primary">{image?.Title} "</p> */}
            </div>
          );
        })}
      </div>
      {/* <div className="image-slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={`z-[2]  slide ${currentImageIndex === index ? "active" : ""}`}
          >
            { /* <img src={image} alt={`Image ${index + 1}`} /> * / }
            <div className="w-full min-h-screen bg-no-repeat bg-top z-[2]" style={{ backgroundImage: `url(${image.BackgroundImg.filename})` }}></div>
            <p className="text-white bg-primary">Hello, this is a text!</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default FadingImageSlider;
