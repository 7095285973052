import { storyblokEditable } from "@storyblok/react";

const HeroBanner = ({ blok }) => {
  return (
    <div
      className=" mb-24 bg-white-half"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <img
        className="absolute"
        alt="Blurred_shapes1"
        src="../images/Blurred_shapes1.png"
      />
      <div className="  mx-auto ">
        <div
          className="w-full h-full bg-cover min-h-screen bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${blok.BackgroundImg.filename})` }}
        >
          <div className="container mx-auto relative w-[100vw] h-[100vh]">
            <div
              className={` container text-center ${
                blok.AlignText === "center" ? "items-center" : "items-start"
              }   sm:w-[90vw] mb:w-96 xs:w-3/4  absolute bottom-[-100px] mb-20 flex flex-col gap-6  ${
                blok.AlignText === "left" && "md:ml-20"
              }   ${
                blok.AlignText === "left"
                  ? "left-0"
                  : blok.AlignText === "center"
                  ? "left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  : "right-0"
              }`}
            >
              <h2
                className={`md:text-6XL text-4XL  font-lora font-regular w-auto  text-white `}
              >
                {blok?.Title}
              </h2>
              {blok?.Title && (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9996 17.6002C11.7746 17.6002 11.5871 17.5252 11.3996 17.3752L2.77461 8.9002C2.43711 8.5627 2.43711 8.0377 2.77461 7.7002C3.11211 7.3627 3.63711 7.3627 3.97461 7.7002L11.9996 15.5377L20.0246 7.6252C20.3621 7.2877 20.8871 7.2877 21.2246 7.6252C21.5621 7.9627 21.5621 8.4877 21.2246 8.8252L12.5996 17.3002C12.4121 17.4877 12.2246 17.6002 11.9996 17.6002Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
