import React from "react";
// import Navigation from "../Global/Header";
// import Footer from "../Global/Footer";
// import Head from "./Head";
// import Navigation from "./Navigation";
// import Footer from "./Footer";

const Layout = ({ children }) => {
    return (
      // <div className=" bg-gradient-to-b from-black to-teal-950" >
      <div>
    {/* <Head /> */}
    {/* <Navigation /> */}
    {children}
    {/* <Footer /> */}
  </div>)
};

export default Layout;
