import React, { useState, useEffect } from "react";
import { storyblokEditable, useStoryblokApi } from "@storyblok/react";
import { Link } from "react-router-dom";

const RelatedArticlesCards = ({ blok }) => {
  const [allArticle, setAllArticle] = useState(null);
  const [relatedArticle, setRelatedArticle] = useState(null);

  const storyblokApi = useStoryblokApi();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await storyblokApi.get("cdn/stories/", {
          version: "draft",
          starts_with: "news/",
        });
        setAllArticle(data);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchBlogs();
  }, [storyblokApi]);

  useEffect(() => {
    if (allArticle && blok.newslist) {
      const filteredArticles = allArticle.stories.filter((article) =>
        blok.newslist.includes(article.uuid)
      );
      setRelatedArticle(filteredArticles);
    }
  }, [allArticle, blok.newslist]);

  return (
    <div
      className="flex flex-col items-center px-20 pb-20 text-base leading-6 bg-white max-md:px-5 z-[11]"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <div className="mt-2 text-4XL pb-4 after:bg-primary after:h-0.5 after:w-5/6 after:flex after:mt-4 font-heavy text-ink-blue font-aileron text-teal-950 text-left mr-auto">
        {blok?.Heading}
      </div>
      <div className="mx-auto mt-16 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        {relatedArticle?.map((blok) => {
          return (
            <>
              <Link key={blok._uid} to={blok.full_slug}>
                <div className="flex flex-col overflow-hidden rounded-lg">
                  <div className="flex-shrink-0">
                    <img
                      className="h-auto w-full object-cover rounded-lg"
                      src={blok.content.body[0]?.image?.filename}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between py-7 font-aileron">
                    <div className="flex-1">
                      <p className="w-fit bg-gray2 px-4 py-1 rounded font-semi-bold text-extra-small-regular">
                        {new Date(blok?.first_published_at).toLocaleDateString(
                          "en-US",
                          {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </p>
                      <p className="mt-6 font-heavy text-XL line-clamp-2">
                        {blok.content.body[0].title}
                      </p>
                      <p className="mt-4 text-primary-text text-small-regular line-clamp-2">
                        {blok.content.body[0].description}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedArticlesCards;
