import React from "react";
import { Link } from "react-router-dom";
import { renderRichText, storyblokEditable } from "@storyblok/react";

const sectors = ({ blok }) => {
  const renderedRichText = renderRichText(blok?.description);
  return (
    <div
      className="lg:px-24 md:px-24 mb:px-8 container mx-auto font-aileron flex flex-col   relative  z-[12]"
      {...storyblokEditable(blok)}
      key={blok?._uid}
    >
      <img
        src="./images/Blurred_shapes3.png"
        alt="Events"
        className="absolute md:left-[-10%] left-[-16%] top-[-30%] z-[-1]"
      />
      {blok?.title && <div className="text-4XL font-heavy leading-10 text-white max-md:max-w-full">
        {blok?.title}
      </div>}
      {blok?.title && (
        <div className="bg-light-green h-[2px] w-[12rem] md:w-[9rem] bottom-5 mt-4" />
      )}
      {renderedRichText && <div className="font-lora mt-11 leading-7 text-white max-md:my-10 max-md:max-w-full text-large-regular">
        {/* {blok.description} */}
        <div
          className="richtext  text-white"
          dangerouslySetInnerHTML={{ __html: renderedRichText }}
        />
      </div>}
      <div className="self-stretch z-[12] my-8 w-full max-md:max-w-full">
        <div
          className={`grid md:${
            blok?.gridOption && blok?.gridOption
          } grid-cols-1 mobile-grid-col gap-5  max-md:gap-4 justify-center mx-auto justify-items-center ${
            blok?.gridOption === "grid-cols-2" &&
            "md-max-width-50 max-w-[900px]"
          }`}
          style={{
            gridTemplateColumns:
              blok?.gridOption === "grid-cols-2"
                ? "repeat(2, 1fr)"
                : blok?.gridOption === "grid-cols-4"
                ? "repeat(4, 1fr)"
                : blok?.gridOption === "grid-cols-3"
                ? "repeat(3, 1fr)"
                : "repeat(1, 1fr)",
          }}
        >
          {blok?.sectorcards.map((blok) => {
            return (
              <a key={blok?._uid} href={blok?.Link?.cached_url}>
                <div className="max-w-md cursor-pointer relative overflow-hidden image-container ">
                  <img
                    // className="zoom-effect md:h-64 md:w-64 sm:h-56 sm:w-56 h-56 w-56 object-cover"
                    className="zoom-effect object-cover"
                    src={blok?.image.filename}
                    alt=""
                  />
                  <div className="overlay md:h-80 md:w-80 sm:h-72 sm:w-72 h-56 w-56"></div>
                  <div className="absolute bottom-0 flex w-full justify-between px-4 border-b-2 border-primary    ">
                    <h5 className="text-2XL font-bold   text-white">
                      {blok?.title}
                    </h5>
                    <img src="../images/rightarrow.svg" alt="" />
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      {blok?.button.map((blok) => {
        return (
          <>
            <Link
              key={blok?._uid}
              to={blok?.ButtonNavigationLink.cached_url}
              target={
                blok?.LinkTarget === "same_page"
                  ? "_self"
                  : blok?.LinkTarget === "new_page"
                  ? "_blank"
                  : "_self"
              }
            >
              <button
                className={`md:mx-12  flex p-3 cursor-pointer justify-center items-center rounded font-aileron font-medium-regular font-semi-bold mt-8 px-12`}
                style={{
                  border:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "1px solid rgb(255, 255, 255)"
                      : "none",
                  color:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "#ffff"
                      : "inherit",
                  backgroundColor:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "#ffffff00"
                      : blok?.buttonBackgroundColor,
                }}
              >
                {blok.ButtonTitle}
              </button>
            </Link>
          </>
        );
      })}
    </div>
  );
};

export default sectors;
