import React from "react";
import { Link } from "react-router-dom";
import { storyblokEditable } from "@storyblok/react";

const WorldMap = ({ blok }) => {
  return (
    <div
      className=" px-9 font-aileron relative"
      {...storyblokEditable(blok)}
      key={blok._uid}
    >
      <div className="flex flex-col item-start  content-center flex-wrap my-2 gap-2">
        <h1 className=" font-heavy text-white leading-9 text-4XL">
          {blok?.Title}
        </h1>
        {blok?.Title && (
          <div className="bg-light-green h-[2px] w-[30%]  md:w-[9rem] bottom-5 mt-4 md:mx-auto"></div>
        )}
      </div>
      <div className=" flex justify-center mt-16 relative md:mb-0 mb-16">
        <img
          className="items-center"
          src={blok.worldMap.filename}
          alt="World-map"
        />
      </div>
      <section className="text-[#012030] relative md:mb-24 md:h-[200px] x0:h-[auto] z-[12]">
        <div className="m-auto mt-6 x0:max-w-fit sm:max-w-96 xs:w-[18rem] md:absolute mb-16 left-0 right-0 md:top-[-60%] top-[-30%]">
          {blok?.card.map((blok) => {
            return (
              <div
                key={blok?._uid}
                className=" flex mt-3 bg-white rounded-[6px]  "
              >
                <div
                  className={`rounded-l-[6px] content-center text-center min-w-[40px] w-auto break-words md:py-3 py-8 px-3 font-heavy text-XL`}
                  style={{ backgroundColor: blok?.color }}
                >
                  {blok.number}
                </div>
                <div className="flex flex-col items-start justify-center py-2 px-3">
                  <p className="font-heavy text-medium-regular">
                    {blok?.title}
                  </p>
                  <p className="text-extra-small-regular font-regular">
                    {blok?.description}
                  </p>
                </div>
              </div>
            );
          })}
          {/* static card below for temporary */}
          {/* <div className=" flex mt-3 bg-white rounded  ">
            <div className="rounded-l-[0.25rem] content-center text-center bg-[#4fe3ec] w-[40px] py-3 px-3 font-heavy text-XL">
              2
            </div>
            <div className="flex flex-col items-start justify-center py-2 px-3">
              <p className="font-heavy text-medium-regular">London</p>
              <p className="text-extra-small-regular font-regular">
                4/4a Bloomsbury Square, London, Greater London, WC1A 2RP
              </p>
            </div>
          </div> */}
          {blok.button.map((blok) => {
            return (
              <>
                <Link
                  key={blok?._uid}
                  to={blok?.ButtonNavigationLink.cached_url}
                  target={
                    blok?.LinkTarget === "same_page"
                      ? "_self"
                      : blok?.LinkTarget === "new_page"
                      ? "_blank"
                      : "_self"
                  }
                >
                  <button
                    className={`mx-auto flex p-3 cursor-pointer justify-center items-center rounded font-aileron font-medium-regular font-semi-bold mt-8 px-12`}
                    style={{
                      border:
                        blok?.buttonBackgroundColor === "#ffff"
                          ? "1px solid rgb(255, 255, 255)"
                          : "none",
                      color:
                        blok?.buttonBackgroundColor === "#ffff"
                          ? "#ffff"
                          : "inherit",
                      backgroundColor:
                        blok?.buttonBackgroundColor === "#ffff"
                          ? "#ffffff00"
                          : blok?.buttonBackgroundColor,
                    }}
                  >
                    {blok.ButtonTitle}
                  </button>
                </Link>
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default WorldMap;
