import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Drawer = ({ isOpen, onClose, children, megaMenuButton }) => {
  const [isOpenState, setIsOpenState] = useState(false);

  const closeDrawer = () => {
    setIsOpenState(false);
    onClose && onClose();
  };
  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);

  return (
    <>
      {/* Overlay */}
      {isOpenState && <div className="fixed inset-0  opacity-50 z-50"></div>}

      {/* Drawer */}
      <div
        className={`px-10 flex flex-col justify-between fixed inset-y-0 right-0  w-full bg-ink-blue z-50 shadow-lg overflow-scroll transition duration-300 transform ${
          isOpenState ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div>
          <div className="flex justify-between items-start text-white my-8">
            <img
              className="max-w-[100%] xs:max-w-[38%] sm:max-w-[80%] md:max-w-[100%] h-auto"
              src="./images/Lewis_logo.png"
              alt="lewis logo"
            />
            {/* Close button */}
            <button onClick={closeDrawer} className=" text-white">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1996 12.0001L22.3496 2.8501C22.6871 2.5126 22.6871 1.9876 22.3496 1.6501C22.0121 1.3126 21.4871 1.3126 21.1496 1.6501L11.9996 10.8001L2.84961 1.6501C2.51211 1.3126 1.98711 1.3126 1.64961 1.6501C1.31211 1.9876 1.31211 2.5126 1.64961 2.8501L10.7996 12.0001L1.64961 21.1501C1.31211 21.4876 1.31211 22.0126 1.64961 22.3501C1.79961 22.5001 2.02461 22.6126 2.24961 22.6126C2.47461 22.6126 2.69961 22.5376 2.84961 22.3501L11.9996 13.2001L21.1496 22.3501C21.2996 22.5001 21.5246 22.6126 21.7496 22.6126C21.9746 22.6126 22.1996 22.5376 22.3496 22.3501C22.6871 22.0126 22.6871 21.4876 22.3496 21.1501L13.1996 12.0001Z"
                  fill="white"
                />
              </svg>

              {/* &times; */}
            </button>
          </div>

          {/* Drawer content */}
          <div className="h-[400px] overflow-auto text-white">{children}</div>
        </div>
        {megaMenuButton?.[0]?.megaMenuButton?.map((blok) => (
          <>
            <Link
              key={blok?._uid}
              to={blok?.ButtonNavigationLink.cached_url}
              target={
                blok?.LinkTarget === "same_page"
                  ? "_self"
                  : blok?.LinkTarget === "new_page"
                  ? "_blank"
                  : "_self"
              }
            >
              <button
                className={`w-[100%] mx-auto border rounded-md flex justify-center items-center  p-2 mb-5 border-spacing-2 font-aileron`}
                style={{
                  border:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "1px solid rgb(255, 255, 255)"
                      : "none",
                  color:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "#ffff"
                      : "inherit",
                  backgroundColor:
                    blok?.buttonBackgroundColor === "#ffff"
                      ? "#ffffff00"
                      : blok?.buttonBackgroundColor,
                }}
                onClick={closeDrawer}
              >
                {blok?.ButtonTitle}
              </button>
            </Link>
          </>
        ))}
      </div>
    </>
  );
};

export default Drawer;
